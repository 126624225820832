<template>
  <div>
    <el-main>
      <head-layout
        :head-btn-options="headBtnOptions"
        :head-title="this.$route.query.type == 'edit' ? ($t('cip.plat.wfOps.proxy.title.editHeadTitle')) : ($t('cip.plat.wfOps.proxy.title.addHeadTitle'))"
        @head-save="headSave('save')"
        @head-save-back="headSave('back')"
        @head-cancel="headCancel">
      </head-layout>

      <form-layout
        :column="option.column"
        :dataForm.sync="dataObj"
        ref="formLayout">
      </form-layout>
    </el-main>
    <user-select ref="user-select" @onConfirm="userCall"></user-select>
  </div>
</template>

<script>
import UserSelect from '../process/components/user-select.vue'
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {update, add, getuserNameById} from "@/api/plugin/workflow/proxy";

export default {
  components: {FormLayout, HeadLayout,UserSelect},

  data() {
    return {
      //区别是委托人还是代理人
      isApplyUser: true,
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: ""
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      dataObj: {},
      option: {
        size: 'mini',
        height: 'auto',
        calcHeight: 30,
        tip: false,
        border: true,
        selection: true,
        dialogType: 'drawer',
        align: 'center',
        searchMenuSpan: 6,
        searchSize: 'mini',
        searchIndex: 3,
        searchIcon: true,
        column: [
          {
            label: this.$t('cip.plat.wfOps.proxy.field.client'),
            prop: "userName",
            type: 'input',
            readonly: true,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.client'),
              trigger: "change"
            }],
            click: this.clickApply
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.agent'),
            prop: "proxyUserName",
            click:  this.clickProxyUser,
            params: {
              checkType: 'radio',
            },
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.agent'),
              trigger: "change"
            }],
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.process'),
            prop: 'process',
            type: 'radio',
            dicData: [{
              label: this.$t('cip.plat.wfOps.proxy.field.total'),
              value: '1'
            }, {
              label: this.$t('cip.plat.wfOps.proxy.field.appoint'),
              value: '2'
            }],
            hide: true,
            change: ({ value }) => {
              const processDefKey = this.findObject(this.option.column, 'processDefKey')
              if (value == '2') {
                processDefKey.display = true
                if (this.form.processDefKey == 'WF_ALL_PROCESS') this.form.processDefKey = ''
              } else {
                processDefKey.display = false
                this.form.processDefKey = 'WF_ALL_PROCESS'
              }
            },
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.process'),
              trigger: "blur"
            }],
            value: '1'
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.specifyTheProcess'),
            prop: "processDefKey",
            dataType: 'string',
            type: 'tree',
            dicUrl: '/api/sinoma-workflow/design/deployment/list?size=-1',
            dicFormatter: (res) => {
              const data = res.data.records
              if (data && data.length > 0) {
                return [{
                  name: this.$t('cip.plat.wfOps.proxy.field.total'),
                  key: 'all',
                  children: data
                }]
              }
              return data
            },
            defaultExpandAll: true,
            leafOnly: true,
            props: {
              label: 'name',
              value: 'key',
            },
            multiple: false,
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.process'),
              trigger: "blur"
            }],
            span: 8,
            overHidden: true,
            search: true,
            display: true
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.type'),
            prop: "type",
            type: 'select',
            // dicData: [{
            //   label: this.$t('cip.plat.wfOps.proxy.field.permanent'),
            //   value: '1'
            // }, {
            //   label: this.$t('cip.plat.wfOps.proxy.field.timing'),
            //   value: '2'
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_timing",
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.type'),
              trigger: "blur"
            }],
            change: ({ value }) => {
              const startTime = this.findObject(this.option.column, 'startTime')
              const endTime = this.findObject(this.option.column, 'endTime')
              if (value == '2') {
                startTime.display = true
                endTime.display = true
              } else {
                startTime.display = false
                endTime.display = false
              }
            },
            span: 8,
            search: true,
            width: 120,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.startTime'),
            prop: "startTime",
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.effectiveTime'),
              trigger: "blur"
            }],
            display: false,
            span: 8,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.endTime'),
            prop: "endTime",
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: this.$t('cip.cmn.rule.selectWarning') + this.$t('cip.plat.wfOps.proxy.field.effectiveTime'),
              trigger: "blur"
            }],
            display: false,
            span: 8,
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.wfOps.proxy.field.status'),
            prop: 'status',
            type: 'select',
            // dicData: [{
            //   label: this.$t('cip.plat.wfOps.proxy.field.available'),
            //   value: 1
            // }, {
            //   label: this.$t('cip.plat.wfOps.proxy.field.forbidden'),
            //   value: 2
            // }],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=is_available",
            value: 1,
            search: true,
            span: 8,
            width: 100
          }
        ]
      },
    }
  },
  created() {
    if (this.$route.query.type == 'edit') {
      this.dataObj = JSON.parse(decodeURIComponent(this.$route.query.data))
    }
    // if(!(this.dataObj.userId && this.dataObj.proxyUserId)) throw new Error('数据错误')
      if(!this.dataObj.userId){
        this.dataObj.userId = 0
      }
      if(!this.dataObj.proxyUserId){
        this.dataObj.proxyUserId = 0
      }
      getuserNameById(this.dataObj.userId).then(res=>{
        this.dataObj.userName = res.data.data.realName
      })
      getuserNameById(this.dataObj.proxyUserId).then(res=>{
        this.dataObj.proxyUserName = res.data.data.realName
      })

  },
  methods: {
    userCall(ids,names){
      if(this.isApplyUser){
        this.dataObj.userName = names;
        this.dataObj.userId = ids;
      }else {
        this.dataObj.proxyUserName = names;
        this.dataObj.proxyUserId = ids;
      }
    },
    clickProxyUser(){
      this.isApplyUser = false
      this.$refs['user-select'].visible = true
    },
    clickApply(){
      this.isApplyUser = true
      this.$refs['user-select'].visible = true
    },
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then(() => {
            if (type == 'back') {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
            //
          }, error => {
            window.console.log(error);

            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled = false
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style>
</style>
